<template>
  <v-row>
    <v-col cols="8">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="pl-3 pr-3">
          <v-col cols="12">
            <v-alert dense border="left" type="warning" v-if="tope_tasa > 0">
              La operatoria de este credito tiene tope Tasa
              {{ tope_tasa * 100 }}%
            </v-alert>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="montoFormatted"
              @input="handlMontoInput"
              @blur="formatParamsMonto"
              :rules="[
                (v) => !!v || 'El monto es obligatorio',
                // (v) =>
                //   v <= parseInt(montoMaximo, 10) ||
                //   'Debe ser menor o igual al monto máximo',
                // (v) =>
                //   v >= parseInt(montoMinimo, 10) ||
                //   'Debe ser mayor o igual al monto mínimo',
                // (v) =>
                //   /^\d+(\.\d{1,2})?$/.test(v) || 'Solo números y punto decimal',
              ]"
              label="Monto"
              :readonly="
                !reAmortizarButtonDisabled
                  ? reAmortizarButtonDisabled
                  : (otorgado && !operatoria_id === 85) || isLiquidado === true
              "
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              label="Tipo Tasa"
              v-model="tipo_tasa"
              :disabled="true"
              :items="[
                { value: '1', text: 'Fija' },
                { value: '2', text: 'Variable' },
              ]"
              :readonly="otorgado || isLiquidado === true"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              label="Tasa de Referencia"
              :disabled="true"
              v-model="tasa_referencia"
              :items="[
                { text: 'Banco Nación', value: '1' },
                { text: 'Libor', value: '2' },
                { text: 'BADLAR', value: '3' },
              ]"
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field-percent
              v-model="tasa"
              :rules="[(v) => !!v || 'La tasa es obligatoria']"
              label="Tasa"
              :readonly="otorgado && !operatoria_id === 85"
              :loading="loadingTasa"
              v-bind:properties="{
                prefix: '%',
                readonly: false,
                disabled: false,
                outlined: false,
                clearable: true,
                placeholder: '',
              }"
              v-bind:options="{
                locale: 'es-AR',
                length: 4,
                precision: 2,
                empty: null,
              }"
            ></v-text-field-percent>
          </v-col>
          <v-col>
            <v-flex class="ml-1">
              <v-text-field-percent
                label="Tasa Registrada"
                v-model="tasaReg"
                disabled
                v-bind:properties="{
                  prefix: '%',
                  readonly: true,
                  disabled: true,
                  outlined: false,
                  clearable: true,
                  placeholder: '',
                }"
                class="greyed-out"
              ></v-text-field-percent> </v-flex
          ></v-col>
          <v-col cols="3">
            <v-text-field
              label="Plazo de Credito"
              v-model="parametrosClientes.plazo"
              :rules="[(v) => !!v || 'El credito del plazo es obligatorio']"
              :readonly="
                !reAmortizarButtonDisabled
                  ? reAmortizarButtonDisabled
                  : (otorgado && !operatoria_id === 85) || isLiquidado === true
              "
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="P. gracia Capital"
              v-model="parametrosClientes.periodo_gracia"
              :rules="[
                (v) => !!v || 'El periodo de gracia capital es obligatorio',
              ]"
              :readonly="
                !reAmortizarButtonDisabled
                  ? reAmortizarButtonDisabled
                  : (otorgado && !operatoria_id === 85) || isLiquidado === true
              "
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              label="P. gracia intereses"
              v-model="parametrosClientes.gracia_intereses"
              :rules="[
                (v) =>
                  v !== '' || 'Período de gracia de intereses es obligatorio',
              ]"
              :readonly="
                !reAmortizarButtonDisabled
                  ? reAmortizarButtonDisabled
                  : (otorgado && !operatoria_id === 85) || isLiquidado === true
              "
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              label="Periocidad de pagos"
              v-model="parametrosClientes.periodicidad_pagos"
              :rules="[(v) => !!v || 'La periodicidad de pagos es obligatoria']"
              :readonly="
                !reAmortizarButtonDisabled
                  ? reAmortizarButtonDisabled
                  : (otorgado && !operatoria_id === 85) || isLiquidado === true
              "
              :items="[
                { value: 1, text: 'Mensual' },
                { value: 2, text: 'Bimestral' },
                { value: 3, text: 'Trimestral' },
                { value: 6, text: 'Semestral' },
                { value: 12, text: 'Anual' },
              ]"
            >
            </v-select>
          </v-col>
          <v-col cols="3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="parametrosClientes.fecha"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formatDate"
                  label="Fecha Inicio"
                  prepend-icon="mdi-calendar"
                  readonly
                  class="text-overflow-ellipsis"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="parametrosClientes.fecha"
                no-title
                scrollable
                :readonly="otorgado && !operatoria_id === 85"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Salir
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(parametrosClientes.fecha)"
                >
                  Aceptar
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
      <v-row class="pl-3 pr-3">
        <v-col cols="12" v-if="parametrosServicio.length < 1">
          <v-alert dense border="left" type="warning">
            La operatoria de este credito aun no tiene parametros cargados
          </v-alert>
        </v-col>
        <v-col
          cols="3"
          v-for="(item, index) in parametrosServicio"
          :key="index"
        >
          <span
            v-if="
              item.tipo_parametro_id.clave == 'cupo_linea' ||
              item.tipo_parametro_id.clave == 'monto_maximo' ||
              item.tipo_parametro_id.clave == 'monto_minimo'
            "
          >
            <v-text-field-money
              v-model="item.valor"
              :ref="item.tipo_parametro_id.clave"
              :label="item.tipo_parametro_id.nombre"
              v-bind:properties="{
                prefix: '$',
                readonly: false,
                disabled: true,
                outlined: false,
                clearable: true,
                placeholder: '',
              }"
              v-bind:options="{
                locale: 'es-AR',
                length: 15,
                precision: 2,
                empty: null,
              }"
            ></v-text-field-money>
          </span>
          <span v-else-if="item.tipo_parametro_id.clave == 'sistema'">
            <v-text-field
              v-if="item.tipo_parametro_id.clave !== 'tipo_tasa'"
              :disabled="true"
              :ref="item.tipo_parametro_id.clave"
              :label="item.tipo_parametro_id.nombre"
              :value="item.valor == 0 ? 'Aleman' : 'Frances'"
            >
            </v-text-field>
          </span>
          <span v-else-if="item.tipo_parametro_id.clave == 'moneda'">
            <v-text-field
              v-if="item.tipo_parametro_id.clave !== 'tipo_tasa'"
              :disabled="true"
              :ref="item.tipo_parametro_id.clave"
              :label="item.tipo_parametro_id.nombre"
              :value="item.valor == 0 ? 'Pesos' : 'Dolares'"
            >
            </v-text-field>
          </span>
          <span v-else>
            <v-text-field
              v-if="item.tipo_parametro_id.clave !== 'tipo_tasa'"
              :disabled="true"
              :ref="item.tipo_parametro_id.clave"
              :label="item.tipo_parametro_id.nombre"
              v-model="item.valor"
            >
            </v-text-field>
          </span>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="4" style="margin-top: 20px">
      <ModalSimulador
        :servParametros="servParametros"
        :fb_underscore="Number(fb_underscore)"
        :fb_tasa="String(fb_tasa)"
      ></ModalSimulador>
      <v-btn
        v-if="parseInt(estado, 10) === 6"
        block
        color="primary"
        :loading="loadingCalcular"
        :disabled="amortizarButtonDisabled || !fechadesembolso"
        @click="calcularCredito()"
        class="boton-amortizar mt-3"
      >
        Amortizar
      </v-btn>
      <br />
      <v-btn
        block
        v-if="parseInt(estado, 10) === 7"
        color="primary"
        :loading="loadingCalcular"
        @click="reCalcularCredito()"
        class="boton-amortizar"
      >
        Re-Amortizar
      </v-btn>
      <ModalGastos
        v-if="parseInt(estado, 10) !== 7 && isLiquidado === false"
        :lineacreditocliente_id="solicitud_id"
        @creditoLiquidado="actualiCaudroLiquidaciones"
      ></ModalGastos>
      <LiquidarBtn
        v-if="parseInt(estado, 10) !== 7 && isLiquidado === false"
        :monto="String(this.monto) || 0"
        :tipo_servicio="tipoServicio"
        :legajo_id="solicitud_id"
        :params="newparams"
      ></LiquidarBtn>
      <!-- <br />
      <v-btn block color="primary" @click="guardarTasaFechaInicio">
       Guardar parametros
      </v-btn> -->
      <ModalProrroga
        v-on:generarProrroga="setCuadro"
        v-if="creditoEstado.toLowerCase().search('mora') >= 0"
        :operatoria_id="operatoria_id"
        :periodicidadPagos="parseInt(parametrosClientes.periodicidad_pagos, 10)"
        class="mt-2"
      ></ModalProrroga>
      <ModalRefinanc
        v-on:generarRefinanc="setCuadro"
        v-if="creditoEstado.toLowerCase().search('mora') >= 0"
        :operatoria_id="operatoria_id"
        :datosParametros="datosParametros"
        :servParametros="servParametros"
        class="mt-5"
      ></ModalRefinanc>
    </v-col>
  </v-row>
</template>
<script>
import axios from 'axios';
import { API_URL, SIMULADOR_URL } from '@/common/config';
import moment from 'moment';
import ModalProrroga from '@/components/ModalProrroga.vue';
import ModalRefinanc from '@/components/ModalRefinanc.vue';
import ModalGastos from '@/components/Gastos/ModalGastos.vue';
import LiquidarBtn from '@/components/LiquidarBtn.vue';
import ModalSimulador from '@/components/ModalSimular.vue';
import { formatMonto } from '../../../common/util';

export default {
  name: 'TablaParametroEjecutivo',
  props: {
    operatoria_id: {
      type: Number,
      required: true,
    },
    cliente: {
      type: Number,
    },
    solicitud_id: {
      type: Number,
      required: true,
    },
    creditoEstado: {
      type: String,
    },
    estado: {
      type: Number,
    },
    update: { type: String },
    fechadesembolso: { type: String },
    isLiquidado: {
      type: Boolean,
    },
  },
  components: {
    LiquidarBtn,
    ModalProrroga,
    ModalRefinanc,
    ModalGastos,
    ModalSimulador,
  },
  data: () => ({
    monto: 0,
    unFormatMonto: 0,
    amortizarButtonDisabled: false,
    reAmortizarButtonDisabled: false,
    menu: false,
    loadingTasa: false,
    tipoServicio: 'financiamiento',
    tipo_tasa: '',
    tasa_referencia: '',
    gastosAdministrativos: '',
    tasa: 0,
    tasaReg: 0,
    valid: false,
    factor_bonificacion: 0,
    loadingCalcular: false,
    parametrosServicio: [],
    parametrosClientes: {
      fecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      plazo: '',
      periodo_gracia: '',
      gracia_intereses: '',
      periodicidad_pagos: '',
    },
    montoMaximo: 0,
    montoMinimo: 0,
    fb_underscore: 1,
    fb_tasa: 1,
    tope_tasa: 0,
    servParametros: [],
    garantia: [],
    datosParametros: [],
    otorgado: false,
    token: localStorage.getItem('token'),
    newparams: [],
    formatter: new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
    }),
  }),
  computed: {
    montoFormatted: {
      get() {
        return this.formatCurrency(this.monto);
      },
      set(value) {
        const valueAsString = String(value || '');
        const cleanValue = valueAsString.replace(/[$,.]/g, '').trim(); // Eliminar $ y .
        const numericValue = parseFloat(cleanValue) / 100; // Dividir por 100

        // Validar que el valor sea un número válido
        if (isNaN(numericValue)) {
          this.monto = 0; // Establecer como 0 si no es válido
        } else {
          this.monto = numericValue; // Guardar el valor numérico
        }
      },
    },
    formatDate() {
      return this.parametrosClientes.fecha
        ? moment(this.parametrosClientes.fecha).format('DD-MM-YYYY')
        : '';
    },
  },

  methods: {
    formatParamsMonto() {
      const numericValue = parseFloat(this.monto);
      if (!isNaN(numericValue)) {
        this.monto = numericValue;
      }
    },
    handlMontoInput(value) {
      this.montoFormatted = value; // Llamar al setter de anticipoFormatted
    },

    formatCurrency(value) {
      if (value === null || value === undefined || value === '') return '';

      return this.formatter.format(parseFloat(value) || 0);
    },
    actualiCaudroLiquidaciones() {
      this.$emit('actualiCaudroLiquidaciones');
    },
    async liquidar() {
      this.$swal({
        title: '¿Esta seguro?',
        text: 'Esta acción liquida el desembolso. El Legajo pasara a estado OTORGADO',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, Cancelar',
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const neto = this.parametrosClientes.monto;
          const query = {
            method: 'POST',
            url: `${API_URL}api/solicitud/liquidacionSubtipo`,
            data: {
              concepto: 'Comprobante Generico operatoria de Credito',
              lineaCreditoCliente_id: this.$route.params.id,
              neto,
              servicio: 'financiamiento',
            },
            headers: {
              'Content-Type': 'application/json',
              token: localStorage.getItem('token'),
            },
          };
          const {
            data: { status, message },
          } = await axios(query);
          if (status === 'success') {
            await this.$swal(
              'Desembolso',
              'Desembolso realizado con exito!!',
              'success'
            );
            this.$router.go(0);
          }
          if (status === 'error') {
            this.$swal('Desembolso', 'Algo salio mal', 'error');
            console.error(message);
          }
        }
      });
    },
    setCuadro(cuadroProrrogado) {
      this.$emit('generarCuadro', cuadroProrrogado);
    },

    async calcularCredito() {
      const formatter = new Intl.NumberFormat('es-AR', {
        maximumFractionDigits: 2,
      });
      try {
        // Validate form data
        if (!this.$refs.form.validate()) {
          return;
        }
        const desembolso = moment(
          this.fechadesembolso.replace(/\//g, '-'),
          'DD/MM/YYYY'
        );
        const fechaD = moment(desembolso, 'DD/MM/YYYY').add(1, 'day');
        const startDate = moment(this.formatDate, 'DD/MM/YYYY');
        const desembolsoDay = desembolso.format('DD');
        const desembolsoMonth = desembolso.format('MM');
        const desembolsoYear = desembolso.format('YYYY');

        if (fechaD.isAfter(startDate)) {
          const startDay = startDate.format('DD');
          const startMonth = startDate.format('MM');
          const startYear = startDate.format('YYYY');

          this.$swal.fire(
            'Error',
            `<div>
      La fecha de desembolso del ${desembolsoDay}/${desembolsoMonth}/${desembolsoYear} debe ser menor a la fecha de inicio del ${startDay}/${startMonth}/${startYear}.
    </div>`
          );
          return;
        }
        const topeTasa = parseFloat(this.tope_tasa) * 100;
        this.tasa = parseFloat(this.tasa);
        let setTasa = this.tasa;

        if (topeTasa > 0) {
          const fbTasa = parseFloat(this.fb_tasa);

          if (topeTasa >= this.tasa) {
            this.tasa = this.tasa * fbTasa;
            setTasa = this.tasa;
          }

          if (topeTasa < this.tasa && topeTasa >= this.tasa * fbTasa) {
            setTasa = this.tasa;
            this.tasa = this.tasa * fbTasa;
          }
          if (this.tasa * fbTasa > topeTasa) {
            setTasa = topeTasa * (1 / fbTasa);
            this.tasa = topeTasa;
          }
        }

        this.loadingCalcular = true;
        const parsedMonto = this.monto || 0;

        // Construct URL with proper formatting
        const url = `${SIMULADOR_URL}${parsedMonto}/${this.parametrosClientes.plazo}/${this.parametrosClientes.periodicidad_pagos}/${setTasa}/${this.formatDate}/${this.parametrosClientes.periodo_gracia}/${this.parametrosClientes.gracia_intereses}/${this.fb_underscore}/${this.fb_tasa}/${desembolsoDay}-${desembolsoMonth}-${desembolsoYear}`;

        // Fetch data from API
        const response = await axios.get(url);
        if (!response.data) {
          this.$swal.fire(
            'Error al generar Desarrollo del Crédito',
            '',
            'error'
          );
          this.loadingCalcular = false;
          return;
        }

        // Formatear y redondear los valores antes de guardar
        const formattedData = Object.fromEntries(
          Object.entries(response.data).map(([key, value]) => {
            const formattedValues = {
              ...value,
              Saldo_Inicial: value.Saldo_Inicial,
              Amortizacion: value.Amortizacion,
              Intereses: value.Intereses,
              Saldo_Final: value.Saldo_Final,
              IVA_Intereses: value.IVA_Intereses,
              Cuota: value.Cuota,
              Cuota_Total: value.Cuota_Total,
            };
            return [key, formattedValues];
          })
        );

        const data = formattedData;

        // Generate HTML table
        const tableHtml = `
          <table class="cc">
            <thead>
              <tr class="text-center">
                <th>Fecha</th>
                <th>N° Cuota</th>
                <th>Saldo Inicial</th>
                <th>Amortización</th>
                <th>Intereses</th>
                <th>Saldo Final</th>
                <th>Días Transcurridos</th>
                <th>IVA Intereses</th>
                <th>Cuota</th>
                <th>Cuota Total</th>
              </tr>
            </thead>
            <tbody>
              ${Object.entries(data)
                .sort(([keyA], [keyB]) => new Date(keyA) - new Date(keyB))
                .map(
                  ([key, value]) => `
                  <tr class="text-center">
                    <td  class="fecha">${key}</td>
                    <td>${value.N_Cuota}</td>
                    <td>$ ${formatter.format(value.Saldo_Inicial)}</td>
                    <td>$ ${formatter.format(value.Amortizacion)}</td>
                    <td>$ ${formatter.format(value.Intereses)}</td>
                    <td>$ ${formatter.format(value.Saldo_Final)}</td>
                    <td>${value.Dias_Transcurridos}</td>
                    <td>$ ${formatter.format(value.IVA_Intereses)}</td>
                    <td>$ ${formatter.format(value.Cuota)}</td>
                    <td>$ ${formatter.format(value.Cuota_Total)}</td>
                  </tr>
                `
                )
                .join('')}
            </tbody>
          </table>
        `;

        // Display results in a modal
        this.$swal
          .fire({
            title: 'Desarrollo del crédito:',
            html: tableHtml,
            icon: 'warning',
            customClass: {
              container: 'swal-left my-custom-modal',
            },
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Guardar',
            focusConfirm: false, // Focus on OK button by default
            // ...other Swal options
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$swal(
                'Amortización',
                'Desarrollo del Crédito generado con exito',
                'success'
              );
              this.guardarTasaFechaInicio();
              this.guardarCuadro(response.data);
              this.setEstadoTramite(this.$route.params.id);
              this.$emit('generarCuadro', Object.entries(response.data));
              this.loadingCalcular = false;
              this.amortizarButtonDisabled = true;
              this.reAmortizarButtonDisabled = false;
            }
          });
      } catch (error) {
        console.error(error);
        this.loadingCalcular = false;
      }
      this.loadingCalcular = false;
    },

    async reCalcularCredito() {
      const formatter = new Intl.NumberFormat('es-AR', {
        maximumFractionDigits: 2,
      });
      try {
        if (!this.$refs.form.validate()) {
          return;
        }
        const desembolso = moment(
          this.fechadesembolso.replace(/\//g, '-'),
          'DD/MM/YYYY'
        );
        const fechaD = moment(desembolso, 'DD/MM/YYYY').add(1, 'day');
        const startDate = moment(this.formatDate, 'DD/MM/YYYY');
        const desembolsoDay = desembolso.format('DD');
        const desembolsoMonth = desembolso.format('MM');
        const desembolsoYear = desembolso.format('YYYY');

        if (fechaD.isAfter(startDate)) {
          const startDay = startDate.format('DD');
          const startMonth = startDate.format('MM');
          const startYear = startDate.format('YYYY');

          this.$swal.fire(
            'Error',
            `<div>
      La fecha de desembolso del ${desembolsoDay}/${desembolsoMonth}/${desembolsoYear} debe ser menor a la fecha de inicio del ${startDay}/${startMonth}/${startYear}.
    </div>`
          );
          return;
        }
        const topeTasa = parseFloat(this.tope_tasa) * 100;
        let setTasa = this.tasa;

        if (topeTasa > 0) {
          const fbTasa = parseFloat(this.fb_tasa);

          if (topeTasa >= this.tasa) {
            this.tasa = this.tasa * fbTasa;
            setTasa = this.tasa;
          }

          if (topeTasa < this.tasa && topeTasa >= this.tasa * fbTasa) {
            setTasa = this.tasa;
            this.tasa = this.tasa * fbTasa;
          }
          if (this.tasa * fbTasa > topeTasa) {
            setTasa = topeTasa * (1 / fbTasa);
            this.tasa = topeTasa;
          }
        }
        this.loadingCalcular = true;
        const parsedMonto = this.monto || 0;
        // Construct URL with proper formatting
        const url = `${SIMULADOR_URL}${parsedMonto}/${this.parametrosClientes.plazo}/${this.parametrosClientes.periodicidad_pagos}/${setTasa}/${this.formatDate}/${this.parametrosClientes.periodo_gracia}/${this.parametrosClientes.gracia_intereses}/${this.fb_underscore}/${this.fb_tasa}/${desembolsoDay}-${desembolsoMonth}-${desembolsoYear}`;

        // Fetch data from API
        const response = await axios.get(url);

        if (!response.data) {
          this.$swal.fire(
            'Error al generar Desarrollo del Crédito',
            '',
            'error'
          );
          this.loadingCalcular = false;
          return;
        }

        // Formatear y redondear los valores antes de guardar
        const formattedData = Object.fromEntries(
          Object.entries(response.data).map(([key, value]) => {
            const formattedValues = {
              ...value,
              Saldo_Inicial: value.Saldo_Inicial,
              Amortizacion: value.Amortizacion,
              Intereses: value.Intereses,
              Saldo_Final: value.Saldo_Final,
              IVA_Intereses: value.IVA_Intereses,
              Cuota: value.Cuota,
              Cuota_Total: value.Cuota_Total,
            };
            return [key, formattedValues];
          })
        );

        const data = formattedData;

        // Generate HTML table
        const tableHtml = `
      <table class="cc">
        <thead>
          <tr class="text-center">
            <th>Fecha</th>
            <th>N° Cuota</th>
            <th>Saldo Inicial</th>
            <th>Amortización</th>
            <th>Intereses</th>
            <th>Saldo Final</th>
            <th>Días Transcurridos</th>
            <th>IVA Intereses</th>
            <th>Cuota</th>
            <th>Cuota Total</th>
          </tr>
        </thead>
        <tbody>
          ${Object.entries(data)
            .sort(([keyA], [keyB]) => new Date(keyA) - new Date(keyB))
            .map(
              ([key, value]) => `
              <tr class="text-center">
                <td  class="fecha">${key}</td>
                <td>${value.N_Cuota}</td>
                <td>$ ${formatter.format(value.Saldo_Inicial)}</td>
                <td>$ ${formatter.format(value.Amortizacion)}</td>
                <td>$ ${formatter.format(value.Intereses)}</td>
                <td>$ ${formatter.format(value.Saldo_Final)}</td>
                <td>${value.Dias_Transcurridos}</td>
                <td>$ ${formatter.format(value.IVA_Intereses)}</td>
                <td>$ ${formatter.format(value.Cuota)}</td>
                <td>$ ${formatter.format(value.Cuota_Total)}</td>
              </tr>
            `
            )
            .join('')}
        </tbody>
      </table>
    `;

        // Display results in a modal
        this.$swal
          .fire({
            title: 'Desarrollo del crédito:',
            html: tableHtml,
            icon: 'warning',
            customClass: {
              container: 'swal-left my-custom-modal',
            },
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Re-Amortizar',
            focusConfirm: false, // Focus on OK button by default
            // ...other Swal options
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$swal(
                'Re-Amortizado',
                'Amortizacion registrada con exito!!',
                'success'
              );
              this.loadingCalcular = false;
              this.guardarCreditoParametros();
              this.updateCuadro(response.data);
              this.$emit('generarCuadro', Object.entries(response.data));
              this.reAmortizarButtonDisabled = true;
            }
          });
      } catch (error) {
        console.error(error);
        this.loadingCalcular = false;
      }
      this.loadingCalcular = false;
    },
    updateCuadro(datos) {
      const data = {
        linea_id: this.$route.params.id,
        datos,
        origen: 'update',
      };
      const url = `${API_URL}api/historicoCuadroMarcha/update`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      axios.post(url, data, header).then((response) => {
        if (response.data.status === 'success') {
          console.log('ok');
        } else if (response.data.status === 'error') {
          console.log('error');
        }
      });
    },
    guardarCuadro(datos) {
      const data = {
        lineaCreditoCliente_id: this.$route.params.id,
        activo: true,
        datos,
        origen: '',
      };
      const url = `${API_URL}api/historicoCuadroMarcha/`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      axios.post(url, data, header).then((response) => {
        if (response.data.status === 'success') {
          console.log('ok');
        } else if (response.data.status === 'error') {
          console.log('error');
        }
      });
    },
    async getTasa() {
      if (this.tipo_tasa === '2') {
        this.loadingTasa = true;
        const data = {
          url: 'https://www.bna.com.ar/Home/InformacionAlUsuarioFinanciero',
          caracter: 8,
        };
        const json = JSON.stringify(data);
        const header = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        await axios
          .post(`${SIMULADOR_URL}tnav`, json, header)
          .then((response) => {
            this.tasa = Number(response.data) * 100;
            this.loadingTasa = false;
          })
          .catch((error) => {
            this.loadingTasa = false;
            console.log(error);
          });
      }
    },
    async guardarTasaFechaInicio() {
      const lineaccId = this.$route.params.id;

      this.datosParametros.find((p) => p.name === 'fecha_inicio').value = this
        .formatDate
        ? this.formatDate
        : '';

      this.datosParametros.find((p) => p.name === 'tasa_amortizacion').value =
        this.tasa;

      const datos = {
        fecha_inicio: this.datosParametros.find(
          (p) => p.name === 'fecha_inicio'
        ),
        tasa_amortizacion: this.datosParametros.find(
          (p) => p.name === 'tasa_amortizacion'
        ),
      };
      await axios
        .post(`${API_URL}api/clienteParametros/update`, {
          token: this.token,
          lineaCredito_id: lineaccId,
          datos,
        })
        .then((response) => {
          if (response.data.status === 'success') {
            this.getParametrosServicio();
            this.getParametrosCliente();
            this.isLiquidado = true;
          } else {
            console.error('No se actualizaron los parametros del Cliente');
          }
        });
    },

    async guardarCreditoParametros() {
      try {
        const lineaccId = this.$route.params.id;

        console.log(
          this.tasa.toLocaleString('es-ES', { minimumFractionDigits: 2 })
        ); // Output: "14,00"

        // Prepare data for the API call
        this.newparams = this.datosParametros.map((p) => ({
          id: p.id,
          name: p.name,
          value:
            p.name === 'monto'
              ? this.monto || 0
              : p.name === 'plazo_credito'
              ? this.parametrosClientes.plazo
              : p.name === 'periodo_gracia'
              ? this.parametrosClientes.periodo_gracia
              : p.name === 'gracia_intereses'
              ? this.parametrosClientes.gracia_intereses
              : p.name === 'periodicidad_pagos'
              ? parseString(this.parametrosClientes.periodicidad_pagos)
              : p.name === 'fecha_inicio'
              ? this.formatDate
              : p.name === 'tasa_amortizacion'
              ? this.tasa.toLocaleString('es-ES', { minimumFractionDigits: 2 })
              : p.name === 'garantia'
              ? this.parametrosClientes.garantia || 0
              : p.name === 'cbu_financiamiento'
              ? this.parametrosClientes.cbu_financiamiento || ''
              : p.value, // keep the original value if not explicitly handled above
        }));

        const response = await axios.patch(
          // `${API_URL}api/clienteParametros/nuevo`,
          `${API_URL}api/v1/clientes-parametros/${lineaccId}/parametros`,
          {
            datos: this.newparams,
          },
          {
            headers: {
              token: this.token,
            },
          }
        );

        if (response.data.status === 'success') {
          await this.$swal.fire({
            title: '¡Éxito!',
            text: 'Los parámetros se guardaron correctamente.',
            icon: 'success',
          });
          await this.getParametrosServicio();
          await this.getParametrosCliente();
          this.$emit('generarCuadroLiquidaciones');
          this.isLiquidado = true;
        } else {
          // Handle errors more gracefully
          this.$swal.fire({
            title: 'Error',
            text:
              response.data.message ||
              'Ocurrió un error al guardar los parámetros.',
            icon: 'error',
          });
          console.error('Error saving parameters:', response.data.message);
        }
      } catch (error) {
        // Handle network errors or other exceptions
        this.$swal.fire({
          title: 'Error',
          text: 'No se pudieron actualizar los parámetros del Cliente. Por favor, inténtelo de nuevo más tarde.',
          icon: 'error',
        });
        console.error('Error saving parameters:', error);
      } finally {
        this.loadingCalcular = false; // Hide loading indicator
        this.$swal.close(); // Close the Swal
      }
    },

    async getParametrosCliente() {
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token'),
        },
      };
      const url = `${API_URL}api/clienteParametros`;
      const data = {
        lineaCreditoCliente_id: parseInt(this.solicitud_id, 10),
      };
      await axios.post(url, data, header).then((result) => {
        const plazocredito = result.data.ClienteParametro.filter(
          (r) => r.tipo_parametro_id.clave === 'plazo_credito'
        );
        const garantia = result.data.ClienteParametro.filter(
          (r) => r.tipo_parametro_id.clave === 'garantia'
        );
        const cbu_financiamiento = result.data.ClienteParametro.filter(
          (r) => r.tipo_parametro_id.clave === 'cbu_financiamiento'
        );
        const monto = result.data.ClienteParametro.filter(
          (r) => r.tipo_parametro_id.clave === 'monto'
        );
        const periodogracia =
          result.data.ClienteParametro.filter(
            (r) => r.tipo_parametro_id.clave === 'periodo_gracia'
          ) || 0;
        const gracia_intereses = result.data.ClienteParametro.filter(
          (r) => r.tipo_parametro_id.clave === 'gracia_intereses'
        );
        const periodicidad = result.data.ClienteParametro.filter(
          (r) => r.tipo_parametro_id.clave === 'periodicidad_pagos'
        );
        const fecha = result.data.ClienteParametro.filter(
          (r) => r.tipo_parametro_id.clave === 'fecha_inicio'
        );
        const tasa_amortizacion = result.data.ClienteParametro.filter(
          (r) => r.tipo_parametro_id.clave === 'tasa_amortizacion'
        );

        this.datosParametros = result.data.ClienteParametro.map((el) => ({
          id: el.tipo_parametro_id.id,
          name: el.tipo_parametro_id.clave,
          value: '',
        }));

        if (!this.datosParametros.some((el) => el.id === 125)) {
          this.datosParametros.push({
            id: 125,
            name: 'gracia_intereses',
            value: '',
          });
        }
        if (!this.datosParametros.some((el) => el.id === 126)) {
          this.datosParametros.push({
            id: 126,
            name: 'tasa_amortizacion',
            value: '',
          });
        }
        if (fecha.length > 0) {
          this.parametrosClientes.fecha = moment(
            fecha[0].valor,
            'DD-MM-YYYY'
          ).format('YYYY-MM-DD');
        }

        if (periodicidad.length > 0) {
          this.parametrosClientes.periodicidad_pagos =
            periodicidad[0].valor === '0'
              ? 1
              : parseInt(periodicidad[0].valor, 10);
        }
        if (plazocredito.length > 0) {
          this.parametrosClientes.plazo = plazocredito[0].valor;
        }
        if (monto.length > 0) {
          const montoValue = monto[0].valor || 0; // Obtener el valor o usar 0 si no existe
          this.parametrosClientes.monto = this.formatMonto(montoValue);

          // Formatear el monto y convertirlo a un número válido
          const montoFormatted = String(this.parametrosClientes.monto).replace(
            /[$,.]/g,
            ''
          );
          this.monto = parseFloat(montoFormatted) / 100 || 0; // Si no es válido, usar 0
        }
        if (periodogracia.length > 0) {
          this.parametrosClientes.periodo_gracia = periodogracia[0].valor;
        }

        if (tasa_amortizacion.length > 0) {
          this.parametrosClientes.tasa_amortizacion =
            tasa_amortizacion[0].valor;
          this.tasaReg = tasa_amortizacion[0].valor || '0,00';
        }

        if (garantia.length > 0) {
          this.parametrosClientes.garantia = garantia[0].valor;
        }
        if (cbu_financiamiento.length > 0) {
          this.parametrosClientes.cbu_financiamiento =
            cbu_financiamiento[0].valor;
        }
        if (gracia_intereses.length > 0) {
          this.parametrosClientes.gracia_intereses = gracia_intereses[0].valor;
        } else {
          this.parametrosClientes.gracia_intereses = 0;
        }
      });
      this.updateNewParams();
    },
    async getParametrosServicio() {
      const query = {
        method: 'GET',
        url: `${API_URL}api/servicioParametros/operatoria/${this.operatoria_id}`,
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token'),
        },
      };
      const {
        data: { parametros, status, message },
      } = await axios(query);
      if (status === 'success') {
        this.tope_tasa = parametros.find(
          (el) => el.tipo_parametro_id.clave === 'tope_tasa'
        )
          ? parametros.find((el) => el.tipo_parametro_id.clave === 'tope_tasa')
              .valor
          : 0;
        this.tipo_tasa = parametros.find(
          (el) => el.tipo_parametro_id.clave === 'tipo_tasa'
        )
          ? parametros.find((el) => el.tipo_parametro_id.clave === 'tipo_tasa')
              .valor
          : '';
        this.tasa_referencia = parametros.find(
          (el) => el.tipo_parametro_id.clave === 'tasa_referencia'
        ).valor;
        const tasa = parametros.find(
          (p) => p.tipo_parametro_id.clave === 'tasa'
        );
        this.tasa = parseFloat(tasa.valor, 10) * 100;
        if (typeof this.tasa === 'undefined') {
          console.error('Esta operatoria no tiene tasa configurada');
        }

        if (this.tipo_tasa === '2') {
          this.getTasa();
        }
        this.montoMaximo = parametros.find(
          (p) => p.tipo_parametro_id.clave === 'monto_maximo'
        ).valor;
        this.montoMinimo = parametros.find(
          (p) => p.tipo_parametro_id.clave === 'monto_minimo'
        ).valor;
        this.gastosAdministrativos = parametros.find(
          (p) => p.tipo_parametro_id.clave === 'gastos_administrativos'
        ).valor;

        this.garantia = parametros.find(
          (p) => p.tipo_parametro_id.clave === 'garantia'
        ).valor;

        this.parametrosServicio = parametros.filter(
          (p) =>
            p.tipo_parametro_id.clave !== 'tipo_tasa' &&
            p.tipo_parametro_id.clave !== 'tasa'
        );

        this.fb_tasa =
          parametros.find(
            (p) => p.tipo_parametro_id.clave === 'bonificacion_tasa'
          ).valor || 1;
        this.fb_underscore =
          parametros.find(
            (p) => p.tipo_parametro_id.clave === 'factor_bonificacion'
          ).valor || 1;
        this.servParametros = parametros.map((f) => ({
          id: f.tipo_parametro_id.id,
          clave: f.tipo_parametro_id.clave,
          lineasCredito: f.lineaCredito_id,
          valor: f.valor,
        }));
      }

      if (status === 'error') console.error(message);
    },
    async setEstadoTramite(lineaccId) {
      const formData = {
        lineaCreditoCliente_id: lineaccId,
        estado: 7,
        cliente_id: this.cliente,
      };
      const header = {
        headers: {
          token: this.token,
        },
      };
      await axios
        .put(`${API_URL}api/servicio/clientes`, formData, header)
        .then((response) => {
          this.alert = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pasarRegular() {
      const formData = {
        lineaCreditoCliente_id: this.$route.params.id,
        cliente_id: this.cliente,
        creditoEstado: 1,
      };
      const header = {
        headers: {
          token: this.token,
        },
      };
      axios
        .put(`${API_URL}api/servicio/clientes/credito`, formData, header)
        .then((response) => {
          this.alert = true;

          this.$router.push('/');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateNewParams() {
      this.newparams = this.datosParametros.map((p) => ({
        id: p.id,
        name: p.name,
        value:
          p.name === 'monto'
            ? this.monto || 0
            : p.name === 'plazo_credito'
            ? this.parametrosClientes.plazo
            : p.name === 'periodo_gracia'
            ? this.parametrosClientes.periodo_gracia
            : p.name === 'gracia_intereses'
            ? this.parametrosClientes.gracia_intereses
            : p.name === 'periodicidad_pagos'
            ? this.parametrosClientes.periodicidad_pagos
            : p.name === 'fecha_inicio'
            ? this.formatDate
            : p.name === 'tasa_amortizacion'
            ? this.tasa
            : p.name === 'garantia'
            ? this.parametrosClientes.garantia || 0
            : p.name === 'cbu_financiamiento'
            ? this.parametrosClientes.cbu_financiamiento || ''
            : p.value, // keep the original value if not explicitly handled above
      }));
    },
  },
  watch: {
    parametrosClientes: {
      deep: true,
      handler() {
        this.updateNewParams();
      },
    },
    tasa() {
      this.updateNewParams();
    },
    monto() {
      this.updateNewParams();
    },
  },

  created() {
    this.formatMonto = formatMonto;
    this.getParametrosServicio();
    this.getParametrosCliente();

    if (this.estado === 7) {
      // otorgado
      this.otorgado = true;
    }
  },
};
</script>
<style>
.my-custom-modal {
  position: absolute; /* Position it independently */
  left: 0;
  top: 0;
  background-color: #fff !important; /* Adjust background color if needed */
  backdrop: static; /* Prevent transparent background */
  margin: auto !important;
}

.cc thead {
  font-weight: 700;
}
.cc thead th {
  font-weight: 700;
}
.cc thead td {
  background-color: #f2f2f2;
  font-size: 12px !important;
}
.cc td {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  font-size: 12px !important;
  padding: 0 5px !important;
}
.swal2-popup {
  width: auto !important ;
}
.boton-simular {
  margin-top: 2px !important; /* Añade un margen inferior de 3px */
}
.error-highlight {
  color: red; /* Or any desired highlight color */
  font-weight: bold;
}
.greyed-out {
  background-color: #f5f5f5;
  color: #ccc;
}
</style>
