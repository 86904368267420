var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"centered-table"},[(_vm.mostrarLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"50"}}):_c('div',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"col-nro"},[_vm._v("N°/Liq.")]),_c('th',{staticClass:"col-nva"},[_vm._v("Nueva liquidación")]),_c('th',{staticClass:"col-date"},[_vm._v("Fecha")]),_c('th',[_vm._v("Anticipo de Cosecha")]),_c('th',[_vm._v("Gastos elaboración")]),_c('th',[_vm._v("Total Liquidación")]),_c('th',{staticClass:"col-document text-center"},[_vm._v("Documento I")]),_c('th',{staticClass:"col-document text-center"},[_vm._v("Documento II")]),_c('th',[_vm._v("Gastos Administrativos")]),_c('th',[_vm._v("Desembolso")]),_c('th',[_vm._v("Fecha Desembolso")])])]),_c('tbody',{staticClass:"text-center"},[(_vm.alertOk || _vm.alertError)?_c('tr',[_c('td',{attrs:{"colspan":11}},[(_vm.alertOk)?_c('v-alert',{attrs:{"value":_vm.alertOk,"border":"bottom","color":"green","dense":"","elevation":"5","prominent":"","type":"success"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(_vm._s(this.mensaje))])],1)],1):_vm._e(),(_vm.alertError)?_c('v-alert',{attrs:{"value":_vm.alertError,"border":"bottom","color":"red","dense":"","elevation":"8","prominent":"","type":"error"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(_vm._s(this.mensaje))])],1)],1):_vm._e()],1)]):_vm._e(),_vm._l((_vm.liquidaciones),function(liquidacion,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.estado === 6),expression:"estado === 6"}],class:{
                'disabled-row': liquidacion.fecha_desembolso !== '',
              },attrs:{"small":"","fab":"","dark":"","color":"warning"},on:{"click":function($event){return _vm.editarAnticipo(liquidacion, index)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{class:{
                'disabled-row':
                  liquidacion.monto === 0 ||
                  liquidacion.fecha_desembolso !== '',
              },attrs:{"small":"","fab":"","dark":"","color":"red"},on:{"click":function($event){return _vm.eliminarAnticipo(index, liquidacion.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),_c('td',{class:{
              'disabled-row':
                liquidacion.monto === 0 ||
                liquidacion.fecha_desembolso !== '',
            }},[_vm._v(" "+_vm._s(liquidacion.fecha)+" ")]),_c('td',{class:{
              'disabled-row':
                liquidacion.monto === 0 ||
                liquidacion.fecha_desembolso !== '',
            }},[_vm._v(" "+_vm._s(_vm.formatCurrency(liquidacion.monto || 0))+" ")]),_c('td',{class:{
              'disabled-row':
                liquidacion.monto === 0 ||
                liquidacion.fecha_desembolso !== '',
            }},[_vm._v(" "+_vm._s(_vm.formatCurrency(liquidacion.gastos || 0))+" ")]),_c('td',{class:{
              'disabled-row':
                liquidacion.monto === 0 ||
                liquidacion.fecha_desembolso !== '',
            }},[_vm._v(" "+_vm._s(_vm.formatCurrency( parseFloat(liquidacion.monto || 0) + parseFloat(liquidacion.gastos || 0) ))+" ")]),_c('td',[_c('span',{staticStyle:{"color":"blue","cursor":"pointer"},on:{"click":function($event){return _vm.verDoc(liquidacion.docI)}}},[_vm._v(" "+_vm._s(liquidacion.nameDocI)+" ")]),_c('div',{staticClass:"mb-8"},[_c('v-file-input',{directives:[{name:"show",rawName:"v-show",value:(
                  liquidacion.nameDocI === '' &&
                  liquidacion.fecha_desembolso === ''
                ),expression:"\n                  liquidacion.nameDocI === '' &&\n                  liquidacion.fecha_desembolso === ''\n                "}],staticClass:"mt-5 small-file-input",attrs:{"label":"Subir axcel","outlined":"","dense":"","show-size":""},model:{value:(_vm.archivoExcel),callback:function ($$v) {_vm.archivoExcel=$$v},expression:"archivoExcel"}})],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
                liquidacion.nameDocI === '' &&
                liquidacion.fecha_desembolso === '' &&
                liquidacion.id
              ),expression:"\n                liquidacion.nameDocI === '' &&\n                liquidacion.fecha_desembolso === '' &&\n                liquidacion.id\n              "}],attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.subirDoc(
                  _vm.archivoExcel,
                  liquidacion.id,
                  liquidacion.numeroDesembolso,
                  1
                )}}},[_c('v-icon',{attrs:{"size":"small"}},[_vm._v("mdi-upload")])],1),_c('v-btn',{attrs:{"disabled":liquidacion.nameDocI === '',"color":"success","small":""},on:{"click":function($event){return _vm.verDoc(liquidacion.docI)}}},[_c('v-icon',{attrs:{"size":"small"}},[_vm._v("mdi-magnify")])],1),_c('v-btn',{attrs:{"disabled":liquidacion.nameDocI === '' ||
                liquidacion.fecha_desembolso !== '',"color":"error","small":""},on:{"click":function($event){return _vm.eliminarDoc(liquidacion.id, 1)}}},[_c('v-icon',{attrs:{"size":"small"}},[_vm._v("mdi-delete")])],1)],1),_c('td',[_c('span',{staticStyle:{"color":"blue","cursor":"pointer"},on:{"click":function($event){return _vm.verDoc(liquidacion.docII)}}},[_vm._v(" "+_vm._s(liquidacion.nameDocII)+" ")]),_c('div',{staticClass:"mb-8"},[_c('v-file-input',{directives:[{name:"show",rawName:"v-show",value:(
                  liquidacion.nameDocII === '' &&
                  liquidacion.fecha_desembolso === ''
                ),expression:"\n                  liquidacion.nameDocII === '' &&\n                  liquidacion.fecha_desembolso === ''\n                "}],staticClass:"mt-5 small-file-input",attrs:{"label":"Subir pdf","outlined":"","dense":"","show-size":""},model:{value:(_vm.archivoPdf),callback:function ($$v) {_vm.archivoPdf=$$v},expression:"archivoPdf"}})],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
                liquidacion.nameDocII === '' &&
                liquidacion.fecha_desembolso === '' &&
                liquidacion.id
              ),expression:"\n                liquidacion.nameDocII === '' &&\n                liquidacion.fecha_desembolso === '' &&\n                liquidacion.id\n              "}],attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.subirDoc(
                  _vm.archivoPdf,
                  liquidacion.id,
                  liquidacion.numeroDesembolso,
                  2
                )}}},[_c('v-icon',{attrs:{"size":"small"}},[_vm._v("mdi-upload")])],1),_c('v-btn',{attrs:{"disabled":liquidacion.nameDocII === '',"color":"success","small":""},on:{"click":function($event){return _vm.verDoc(liquidacion.docII)}}},[_c('v-icon',{attrs:{"size":"small"}},[_vm._v("mdi-magnify")])],1),_c('v-btn',{attrs:{"disabled":liquidacion.nameDocII === '' ||
                liquidacion.fecha_desembolso !== '',"color":"error","small":""},on:{"click":function($event){return _vm.eliminarDoc(liquidacion.id, 2)}}},[_c('v-icon',{attrs:{"size":"small"}},[_vm._v("mdi-delete")])],1)],1),_c('td',{class:{
              'disabled-row':
                liquidacion.monto === 0 ||
                liquidacion.fecha_desembolso !== '',
            }},[_vm._v(" "+_vm._s(_vm.formatCurrency(liquidacion.gastosAdmin || 0))+" ")]),_c('td',{class:{
              'disabled-row':
                liquidacion.monto === 0 ||
                liquidacion.fecha_desembolso !== '',
            }},[_vm._v(" "+_vm._s(_vm.formatCurrency( parseFloat(liquidacion.monto || 0) + parseFloat(liquidacion.gastos || 0) - parseFloat(liquidacion.gastosAdmin || 0) ))+" ")]),_c('td',{staticClass:"disabled-row"},[_vm._v(_vm._s(liquidacion.fecha_desembolso))])])})],2),_c('tfoot',[_c('tr',{staticClass:"highlight"},[_c('td',{attrs:{"colspan":"2"}},[_vm._v("TOTALES:")]),_c('td',{attrs:{"colspan":"1"}}),_c('td',[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.totalAnticipos))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.totalGastos))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.totalLiquidaciones))+" ")]),_c('td',{attrs:{"colspan":"2"}}),_c('td',[_vm._v(_vm._s(_vm.formatCurrency(_vm.totalGastosAdministrativos)))]),_c('td',[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.totalLiquidaciones - _vm.totalGastosAdministrativos))+" ")]),_c('td',{attrs:{"colspan":"1"}})]),_c('tr',[_c('td',{attrs:{"colspan":"8"}})]),_c('tr',[_c('td',{attrs:{"colspan":"4"}}),_c('td',{staticClass:"highlight"},[_vm._v("MONTO APROBADO")]),_c('td',{staticClass:"highlight"}),_c('td',{staticClass:"highlight"},[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.montoAprobado))+" ")])]),_c('tr',[_c('td',{attrs:{"colspan":"4"}}),_c('td',{staticClass:"highlight"},[_vm._v("TOTAL ANTICIPO")]),_c('td',{staticClass:"highlight"}),_c('td',{staticClass:"highlight"},[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.totalLiquidaciones))+" ")])]),_c('tr',[_c('td',{attrs:{"colspan":"4"}}),_c('td',{staticClass:"highlight"},[_vm._v("SALDO PENDIENTE")]),_c('td',{staticClass:"highlight"}),_c('td',{staticClass:"highlight"},[_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.saldoPendiente))+" ")])])])])],1),_c('ModalEditarAnticipo',{attrs:{"habilitar":_vm.modalEdit,"indice":_vm.indice,"data":_vm.data,"saldoEditable":_vm.saldo,"modo":_vm.modo},on:{"editAnticipo":_vm.editAnticipo,"nuevoAnticipo":_vm.nuevoAnticipo,"update:habilitar":function($event){_vm.modalEdit = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }