<template>
  <div class="centered-table">
    <v-progress-circular
      v-if="mostrarLoading"
      indeterminate
      color="primary"
      size="50"
    ></v-progress-circular>
    <div v-else>
      <v-simple-table>
        <thead>
          <tr>
            <th class="col-nro">N°/Liq.</th>
            <th class="col-nva">Nueva liquidación</th>
            <th class="col-date">Fecha</th>
            <th>Anticipo de Cosecha</th>
            <th>Gastos elaboración</th>
            <th>Total Liquidación</th>
            <th class="col-document text-center">Documento I</th>
            <th class="col-document text-center">Documento II</th>
            <th>Gastos Administrativos</th>
            <th>Desembolso</th>
            <th>Fecha Desembolso</th>
          </tr>
        </thead>
        <tbody class="text-center">
          <tr v-if="alertOk || alertError">
            <td :colspan="11">
              <v-alert
                v-if="alertOk"
                :value="alertOk"
                border="bottom"
                color="green"
                dense
                elevation="5"
                prominent
                type="success"
              >
                <v-row align="center">
                  <v-col class="grow">{{ this.mensaje }}</v-col>
                </v-row>
              </v-alert>
              <v-alert
                v-if="alertError"
                :value="alertError"
                border="bottom"
                color="red"
                dense
                elevation="8"
                prominent
                type="error"
              >
                <v-row align="center">
                  <v-col class="grow">{{ this.mensaje }}</v-col>
                </v-row>
              </v-alert>
            </td>
          </tr>

          <tr v-for="(liquidacion, index) in liquidaciones" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              <v-btn
                v-show="estado === 6"
                small
                fab
                dark
                color="warning"
                @click="editarAnticipo(liquidacion, index)"
                :class="{
                  'disabled-row': liquidacion.fecha_desembolso !== '',
                }"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                small
                fab
                dark
                color="red"
                @click="eliminarAnticipo(index, liquidacion.id)"
                :class="{
                  'disabled-row':
                    liquidacion.monto === 0 ||
                    liquidacion.fecha_desembolso !== '',
                }"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
            <td
              :class="{
                'disabled-row':
                  liquidacion.monto === 0 ||
                  liquidacion.fecha_desembolso !== '',
              }"
            >
              {{ liquidacion.fecha }}
            </td>
            <td
              :class="{
                'disabled-row':
                  liquidacion.monto === 0 ||
                  liquidacion.fecha_desembolso !== '',
              }"
            >
              {{ formatCurrency(liquidacion.monto || 0) }}
            </td>
            <td
              :class="{
                'disabled-row':
                  liquidacion.monto === 0 ||
                  liquidacion.fecha_desembolso !== '',
              }"
            >
              {{ formatCurrency(liquidacion.gastos || 0) }}
            </td>
            <td
              :class="{
                'disabled-row':
                  liquidacion.monto === 0 ||
                  liquidacion.fecha_desembolso !== '',
              }"
            >
              {{
                formatCurrency(
                  parseFloat(liquidacion.monto || 0) + parseFloat(liquidacion.gastos || 0)
                )
              }}
            </td>
            <td>
              <span
                style="color: blue; cursor: pointer"
                v-on:click="verDoc(liquidacion.docI)"
              >
                {{ liquidacion.nameDocI }}
              </span>
              <div class="mb-8">
                <v-file-input
                  v-show="
                    liquidacion.nameDocI === '' &&
                    liquidacion.fecha_desembolso === ''
                  "
                  class="mt-5 small-file-input"
                  v-model="archivoExcel"
                  label="Subir axcel"
                  outlined
                  dense
                  show-size
                ></v-file-input>
              </div>
              <v-btn
                v-show="
                  liquidacion.nameDocI === '' &&
                  liquidacion.fecha_desembolso === '' &&
                  liquidacion.id
                "
                color="primary"
                small
                v-on:click="
                  subirDoc(
                    archivoExcel,
                    liquidacion.id,
                    liquidacion.numeroDesembolso,
                    1
                  )
                "
              >
                <v-icon size="small">mdi-upload</v-icon>
              </v-btn>
              <v-btn
                :disabled="liquidacion.nameDocI === ''"
                color="success"
                small
                v-on:click="verDoc(liquidacion.docI)"
              >
                <v-icon size="small">mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                :disabled="
                  liquidacion.nameDocI === '' ||
                  liquidacion.fecha_desembolso !== ''
                "
                color="error"
                small
                v-on:click="eliminarDoc(liquidacion.id, 1)"
              >
                <v-icon size="small">mdi-delete</v-icon>
              </v-btn>
            </td>
            <td>
              <span
                style="color: blue; cursor: pointer"
                v-on:click="verDoc(liquidacion.docII)"
              >
                {{ liquidacion.nameDocII }}
              </span>
              <div class="mb-8">
                <v-file-input
                  v-show="
                    liquidacion.nameDocII === '' &&
                    liquidacion.fecha_desembolso === ''
                  "
                  class="mt-5 small-file-input"
                  v-model="archivoPdf"
                  label="Subir pdf"
                  outlined
                  dense
                  show-size
                ></v-file-input>
              </div>
              <v-btn
                v-show="
                  liquidacion.nameDocII === '' &&
                  liquidacion.fecha_desembolso === '' &&
                  liquidacion.id
                "
                color="primary"
                small
                v-on:click="
                  subirDoc(
                    archivoPdf,
                    liquidacion.id,
                    liquidacion.numeroDesembolso,
                    2
                  )
                "
              >
                <v-icon size="small">mdi-upload</v-icon>
              </v-btn>
              <v-btn
                :disabled="liquidacion.nameDocII === ''"
                color="success"
                small
                v-on:click="verDoc(liquidacion.docII)"
              >
                <v-icon size="small">mdi-magnify</v-icon>
              </v-btn>
              <v-btn
                :disabled="
                  liquidacion.nameDocII === '' ||
                  liquidacion.fecha_desembolso !== ''
                "
                color="error"
                small
                v-on:click="eliminarDoc(liquidacion.id, 2)"
              >
                <v-icon size="small">mdi-delete</v-icon>
              </v-btn>
            </td>
            <td
              :class="{
                'disabled-row':
                  liquidacion.monto === 0 ||
                  liquidacion.fecha_desembolso !== '',
              }"
            >
              {{ formatCurrency(liquidacion.gastosAdmin || 0) }}
            </td>
            <td
              :class="{
                'disabled-row':
                  liquidacion.monto === 0 ||
                  liquidacion.fecha_desembolso !== '',
              }"
            >
              {{
                formatCurrency(
                  parseFloat(liquidacion.monto || 0) +
                    parseFloat(liquidacion.gastos || 0) -
                    parseFloat(liquidacion.gastosAdmin || 0)
                )
              }}
            </td>
            <td class="disabled-row">{{ liquidacion.fecha_desembolso }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="highlight">
            <td colspan="2">TOTALES:</td>
            <td colspan="1"></td>
            <td>
              {{ formatCurrency(totalAnticipos) }}
            </td>
            <td>
              {{ formatCurrency(totalGastos) }}
            </td>
            <td>
              {{ formatCurrency(totalLiquidaciones) }}
            </td>
            <td colspan="2"></td>
            <td>{{ formatCurrency(totalGastosAdministrativos) }}</td>
            <td>
              {{
                formatCurrency(totalLiquidaciones - totalGastosAdministrativos)
              }}
            </td>
            <td colspan="1"></td>
          </tr>
          <tr>
            <td colspan="8"></td>
          </tr>
          <tr>
            <td colspan="4"></td>
            <td class="highlight">MONTO APROBADO</td>
            <td class="highlight"></td>
            <td class="highlight">
              {{ formatCurrency(montoAprobado) }}
            </td>
          </tr>

          <tr>
            <td colspan="4"></td>
            <td class="highlight">TOTAL ANTICIPO</td>
            <td class="highlight"></td>
            <td class="highlight">
              {{ formatCurrency(totalLiquidaciones) }}
            </td>
          </tr>
          <tr>
            <td colspan="4"></td>
            <td class="highlight">SALDO PENDIENTE</td>
            <td class="highlight"></td>
            <td class="highlight">
              {{ formatCurrency(saldoPendiente) }}
            </td>
          </tr>
        </tfoot>
      </v-simple-table>
    </div>
    <ModalEditarAnticipo
      :habilitar="modalEdit"
      :indice="indice"
      :data="data"
      :saldoEditable="saldo"
      :modo="modo"
      @editAnticipo="editAnticipo"
      @nuevoAnticipo="nuevoAnticipo"
      @update:habilitar="modalEdit = $event"
    ></ModalEditarAnticipo>
  </div>
</template>

<script>
import axios from 'axios';
import { API_URL } from '@/common/config';
import ModalEditarAnticipo from '../ModalEditarAnticipo.vue';

import { showSimpleMessage, showConfirmacionMessage } from '../../common/util';
import Desembolsos from './Desembolsos.vue';

export default {
  components: { ModalEditarAnticipo },
  props: ['legajo', 'reRender', 'max_liquidacion', 'montoAprobado', 'estado'],
  name: 'LiquidacionAnticipo',
  data: () => ({
    formatter: new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
    }),
    modalEdit: false,
    indice: 0,
    data: {
      monto: '',
      gastosAdmin: '',
      fecha: '',
      desembolso: '0',
      id: 0,
    },
    saldo: 0,
    totalEditable: 0,

    modo: 'editar',
    liquidaciones: [],
    mostrarLoading: false,
    mensaje: '',
    alertError: false,
    alertOk: false,
    archivoExcel: [], // Estado para el archivo de Excel
    archivoPdf: [], // Estado para el archivo de PDF
  }),

  computed: {
    // Calculate total anticipos
    totalAnticipos() {
      return this.liquidaciones.reduce(
        (sum, liquidacion) => sum + parseFloat(liquidacion.monto  || 0),
        0
      );
    },

    // Calculate total gastos
    totalGastos() {
      return this.liquidaciones.reduce(
        (sum, liquidacion) => sum + parseFloat(liquidacion.gastos  || 0),
        0
      );
    },

    // Calculate total liquidaciones (suma de anticipo y gastos)
    totalLiquidaciones() {
      return this.liquidaciones.reduce(
        (sum, liquidacion) =>
          sum +
            (parseFloat(liquidacion.monto  || 0) + parseFloat(liquidacion.gastos  || 0)),
        0
      );
    },
    totalGastosAdministrativos() {
      return this.liquidaciones.reduce(
        (sum, liquidacion) =>
          sum + parseFloat(liquidacion.gastosAdmin || 0),
        0
      );
    },

    // Calculate saldo pendiente
    saldoPendiente() {
      this.saldo = parseFloat(this.montoAprobado  || 0) - this.totalLiquidaciones;

      return this.saldo;
    },

    // Calculate Total Anticipo (suma de los totales de cada fila)
    totalAnticipo() {
      return this.liquidaciones.reduce(
        (sum, liquidacion) =>
          sum +
            (parseFloat(liquidacion.monto  || 0) + parseFloat(liquidacion.gastos  || 0)),
        0
      );
    },
  },

  methods: {
    hideAlertAfterTimeout(timeout = 5000) {
      setTimeout(() => {
        this.alertError = false;
        this.mensaje = '';
        this.alertOk = false;
        this.mostrarLoading = false;
        this.archivoExcel = [];
        this.archivoPdf = [];
      }, timeout);
    },
    async subirArchivo(archivo, id, desembolso, doc, extensionesValidas) {
      // Validación de formato
      const extension = archivo.name.split('.').pop().toLowerCase();
      if (!extensionesValidas.includes(extension)) {
        this.archivoExcel = [];
        this.archivoPdf = [];
        this.mostrarLoading = false;
        this.alertError = true;
        this.mensaje = 'El formato del archivo no es válido.';
        this.hideAlertAfterTimeout();
        showSimpleMessage(
          'Alerta',
          `Los formatos aceptados son: ${extensionesValidas.join(', ')}`,
          'error',
          3000
        );

        return;
      }

      // Validación de tamaño
      if (archivo.size > 10 * 1024 * 1024) {
        this.alertError = true;
        this.mostrarLoading = false;
        this.mensaje = 'El tamaño excede 10MB';
        showSimpleMessage(
          'Alerta',
          `El documento excede el tamaño máximo de 10 MB.`,
          'error',
          3000
        );
        this.hideAlertAfterTimeout();

        return;
      }

      // Configuración de la petición
      const url = `${API_URL}api/documentaciondesembolso/`;
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: localStorage.getItem('token'),
        },
      };
      const formData = new FormData();
      formData.append('id', id);
      formData.append('legajo', this.legajo);
      formData.append('desembolsoNro', desembolso);
      formData.append('doc', doc);
      formData.append('adjunto', archivo);

      try {
        const response = await axios.post(url, formData, header);

        if (response.data.status === 'success') {
          this.getLiquidaciones();
          this.alertOk = true;
          this.mostrarLoading = false;
          showSimpleMessage('Success', 'Documento almacenado', 'success', 1500);
          this.$emit('listar-liquidaciones');
          this.hideAlertAfterTimeout();
        } else {
          this.alertError = true;
          this.mensaje = response.data.message;
          this.hideAlertAfterTimeout();
        }
      } catch (error) {
        console.error('Error al subir el archivo:', error);
        this.alertError = true;
        this.mensaje = 'Hubo un error al procesar la solicitud.';
        this.hideAlertAfterTimeout();
      }
    },

    async subirDoc(archivo, id, desembolso, doc) {
      if (!archivo || archivo.length <= 0) {
        return;
      }
      this.mostrarLoading = true;

      // Reutilizar la función de subida de archivos
      if (doc === 1) {
        await this.subirArchivo(archivo, id, desembolso, doc, [
          'xls',
          'xlsx',
          'csv',
        ]);
      } else if (doc === 2) {
        await this.subirArchivo(archivo, id, desembolso, doc, ['pdf']);
      }
      this.hideAlertAfterTimeout();
    },
    eliminarDoc(documentoId, doc) {
      this.mostrarLoading = true;
      const url = `${API_URL}api/documentaciondesembolso/${documentoId}/${doc}`;
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: localStorage.getItem('token'),
        },
      };

      axios
        .delete(url, header)
        .then(async (response) => {
          if (response.data.status === 'success') {
            this.alertOk = true;
            this.mensaje = 'Documento eliminado Correctamente';
            await showSimpleMessage(
              'Success',
              'Documento eliminado',
              'success',
              1500
            );
            this.getLiquidaciones();
            this.hideAlertAfterTimeout();
          } else {
            this.alertError = true;
            this.mensaje = response.data.message;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    verDoc(url) {
      window.open(url);
    },
    formatCurrency(value) {
      if (value === null || value === undefined || value === '') return '';
      return this.formatter.format(parseFloat(value) || 0);
    },

    async eliminarAnticipo(index, liquidacionId) {
      if (index >= 0) {
        const confirma = await showConfirmacionMessage(
          '¿Confirma la eliminación?',
          `Se va a eliminar la liquidacion N°:${index + 1}`,
          'warning',
          true
        );

        if (confirma) {
          const url = `${API_URL}api/desembolsos/${liquidacionId}`;
          const header = {
            headers: {
              token: localStorage.getItem('token'),
            },
          };

          axios
            .delete(url, header)
            .then(async (response) => {
              if (response.data.status === 'success') {
                this.alertOk = true;
                await showSimpleMessage(
                  'Success',
                  'Desembolso eliminado',
                  'success',
                  1500
                );

                this.getLiquidaciones();
                this.hideAlertAfterTimeout();
              } else {
                this.alertError = true;
                const mensaje = response.data.msg;
                await showSimpleMessage(
                  'Info',
                  `Error al eliminar el desembolso, ${mensaje}`,
                  'info',
                  1500
                );
                this.alertError = false;
              }
            })
            .catch((error) => {
              this.alertError = true;
              console.log(error);
              showSimpleMessage(
                'Error',
                `Error al eliminar el desembolso, ${error}`,
                'error',
                1500
              );
              this.alertError = false;
            });
        }
      }
    },
    editarAnticipo(liquidacion, index) {
      if (this.max_liquidacion <= index) {
        return showSimpleMessage(
          'Error',
          `Ya completó el máximo de liquidaciones: ${this.max_liquidacion}`,
          'error',
          1500
        );
      }
      this.modo =
        liquidacion.monto === 0 && liquidacion.gastos === 0
          ? 'crear'
          : 'editar'; // Asignar el modo (editar o crear)
      this.modalEdit = true; // Abrir el modal
      this.indice = index; // Pasar el índice de la liquidación o nuevo índice

      if (this.modo === 'crear') {
        // Si es creación, pasar datos vacíos
        this.data = {
          monto: 0,
          gastos: 0,
          gastosAdmin: 0,
          fecha: '01-01-2025',
          desembolso: this.indice + 1,
        };
        this.saldo = this.saldoPendiente; // Pasar solo el saldo pendiente
      } else {
        const totalLiquidacionActual =
          parseFloat(liquidacion.monto  || 0) + parseFloat(liquidacion.gastos  || 0);
        this.saldo = totalLiquidacionActual + this.saldoPendiente;

        if (this.modo === 'editar') {
          this.totalEditable = this.saldo;
        }
        // Si es edición, pasar los datos de la fila
        this.data = { ...liquidacion, totalEditable: this.totalEditable };

        // Calcular el saldo editable
      }
    },

    async editAnticipo(datos) {
      // Validación de datos
      const isValid = await this.validateData(datos);
      if (!isValid) return (this.modalEdit = true);

      const data = {
        id: datos.id,
        legajo: this.legajo,
        monto: datos.monto || 0,
        gastos: datos.gastos || 0,
        gastos_admin: datos.gastosAdmin,
        fecha: datos.fecha,
        desembolso: datos.indice + 1,
      };

      const url = `${API_URL}api/desembolsos`;

      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };

      try {
        const response = await axios.put(url, data, header);

        if (response.data && response.data.status === 'success') {
          data.monto = 0;
          data.gastos = 0;
          data.gastos_admin = 0;
          this.alertOk = true;
          this.getLiquidaciones();

          // Usar await con showSimpleMessage
          await new Promise((resolve) => {
            showSimpleMessage('success', 'Desembolso Creado', 'success', 3000);
            setTimeout(resolve, 3000); // Esperar el tiempo que tarda el mensaje (3000 ms)
          });
          // this.$router.go(0);
          this.hideAlertAfterTimeout();
        } else {
          this.alertError = true;
          const mensaje = response.data
            ? response.data.message
            : 'Respuesta inválida de la API';

          await new Promise((resolve) => {
            showSimpleMessage('Error', `Error: ${mensaje}`, 'error', 3000);
            setTimeout(resolve, 3000); // Esperar el tiempo que tarda el mensaje (3000 ms)
          });
          this.alertError = false;
        }
      } catch (error) {
        console.log(error);
        this.alertError = true;
        await new Promise((resolve) => {
          showSimpleMessage(
            'Error',
            'Hubo un error al procesar la solicitud',
            'error',
            3000
          );
          setTimeout(resolve, 3000); // Esperar el tiempo que tarda el mensaje (3000 ms)
          this.alertError = false;
        });
      } finally {
        this.modalEdit = false;
      }
    },
    async nuevoAnticipo(datos) {
      // Validación de datos
      const isValid = await this.validateData(datos);
      if (!isValid) return;

      const data = {
        legajo: this.legajo,
        monto: datos.monto,
        gastos: datos.gastos,
        gastos_admin: datos.gastosAdmin,
        fecha: datos.fecha,
        desembolso: datos.indice + 1,
      };

      const url = `${API_URL}api/desembolsos`;
      const headers = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };

      try {
        const response = await axios.post(url, data, headers);

        if (response.data.status === 'success') {
          this.alertOk = true;
          this.getLiquidaciones();

          // Usar await con showSimpleMessage
          await new Promise((resolve) => {
            showSimpleMessage('success', 'Desembolso Creado', 'success', 3000);
            setTimeout(resolve, 3000); // Esperar el tiempo que tarda el mensaje (3000 ms)
          });

          this.hideAlertAfterTimeout();
        } else {
          this.alertError = true;
          const mensaje = response.data.message;

          await new Promise((resolve) => {
            showSimpleMessage('Error', `Error: ${mensaje}`, 'error', 3000);
            setTimeout(resolve, 3000); // Esperar el tiempo que tarda el mensaje (3000 ms)
          });
          this.alertError = false;
        }
      } catch (error) {
        console.error('Error al procesar la solicitud:', error);
        this.alertError = true;
        await new Promise((resolve) => {
          showSimpleMessage(
            'Error',
            'Hubo un error al procesar la solicitud',
            'error',
            3000
          );
          setTimeout(resolve, 3000); // Esperar el tiempo que tarda el mensaje (3000 ms)
          this.alertError = false;
        });
      } finally {
        this.modalEdit = false;
      }
    },

    async validateData(datos) {
      if (
        (datos.monto === 0 && datos.gastos === 0) ||
        datos.fecha === '' ||
        isNaN(datos.monto)
      ) {
        await showSimpleMessage(
          'Info',
          'Debe completar "Anticipo de Cocecha" u "Gastos de Elaboraión" ',
          'info',
          1500
        );
        return false;
      }
      return true;
    },

    createAnticipo(datos) {
      // Agregar un nuevo anticipo a la lista
      this.liquidaciones.push({
        fecha: datos.fecha,
        anticipo: datos.anticipo,
        gastos: datos.gastos,
        gastosAdmin: datos.gastosAdmin,
        fecha_desembolso: '', // Fecha de desembolso vacía por defecto
      });

      // Cerrar el modal
      this.modalEdit = false;
    },

    async getLiquidaciones() {
      this.mostrarLoading = true;

      try {
        const header = {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: localStorage.getItem('token'),
          },
        };
        const url = `${API_URL}api/desembolsos/${this.legajo}`;
        const { data } = await axios.get(url, header);

        if (data.status === 'Error') {
          this.alertError = true;
          return showSimpleMessage(
            'Error',
            'Error al cargar los desembolsos',
            'error',
            1500
          );
        }
        if (data.status === 'success') {
          this.mostrarLoading = false;
          this.liquidaciones =
            data.listDesembolsos.length === 0
              ? [
                  {
                    fecha: '01-01-2020',
                    monto: 0,
                    gastos: 0,
                    gastosAdmin: 0,
                    fecha_desembolso: '',
                    nameDocI: '',
                    nameDocII: '',
                  },
                ]
              : data.listDesembolsos;

          if (this.max_liquidacion > this.liquidaciones.length) {
            // Clona las liquidaciones actuales
            const liquidaciones = [...this.liquidaciones];
            // Agrega una fila adicional si no existe ya
            if (
              (liquidaciones.length === 0 ||
                liquidaciones[liquidaciones.length - 1].fecha !==
                  '01-01-2020') &&
              liquidaciones[liquidaciones.length - 1].fecha_desembolso !== '' &&
              !this.saldoPendiente <= 0
            ) {
              liquidaciones.push({
                fecha: '01-01-2020',
                monto: 0,
                gastos: 0,
                gastosAdmin: 0,
                fecha_desembolso: '',
                nameDocI: '',
                nameDocII: '',
              });
            }
            this.liquidaciones = liquidaciones;
          }

          if (parseInt(this.max_liquidacion) === this.liquidaciones.length) {
            this.alertOk = true;
            this.mensaje = `Ya completo el maximo de liquidaciones: ${this.max_liquidacion}`;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  async created() {
    await this.getLiquidaciones();
  },
};
</script>

<style>
.swal2-title,
.swal2-content,
.swal2-styled {
  font-family: Roboto, sans-serif !important;
}
.my-container {
  display: flex;
  justify-content: flex-end;
}
d .disabled-row {
  opacity: 0.5;
  pointer-events: none;
  text-align: center;
}
.highlight {
  background-color: #d8d5d5 !important;
  font-weight: bold;
}
.small-file-input {
  font-size: 12px; /* Reduce el tamaño del texto */
  height: 25px; /* Reduce la altura del input */
}
.col-document {
  width: 18%; /* Ajusta según tus necesidades */
}
/* Contenedor para hacer la tabla responsiva */
.table-container {
  overflow-x: auto; /* Habilita desplazamiento horizontal */
  width: 100%; /* Ocupa todo el ancho disponible */
}

/* Ajustar el ancho de las columnas */
.col-number {
  width: 5%; /* Ajusta según tus necesidades */
}
.col-nva {
  width: 10%; /* Ajusta según tus necesidades */
}
.col-action {
  width: 10%; /* Ajusta según tus necesidades */
}
.col-date {
  width: 10%; /* Ajusta según tus necesidades */
}
.col-amount {
  width: 12%; /* Ajusta según tus necesidades */
}
/* Añadir espacio entre los botones */
.spaced-button {
  margin-right: 8px; /* Ajusta el valor según el espacio deseado */
}
.text-center {
  text-align: center; /* Centra el texto horizontalmente */
}
</style>
