<template>
  <v-btn @click="liquidarFinanciamiento" class="info" block> Liquidar </v-btn>
</template>
<script>
/* eslint-disable camelcase */
import { API_URL } from '@/common/config';

const axios = require('axios');

export default {
  name: 'LiquidarBtn',
  props: ['legajo_id', 'monto', 'tipo_servicio', 'params'],
  methods: {
    async guardarCreditoParametros() {
      try {
        const response = await axios.patch(
          `${API_URL}api/v1/clientes-parametros/${this.legajo_id}/parametros`,
          {
            datos: this.params,
          },
          {
            headers: {
              token: this.token,
            },
          }
        );
        if (response.data.status === 'success') {
          await this.$swal.fire({
            title: '¡Éxito!',
            text: 'Los parámetros se guardaron correctamente.',
            icon: 'success',
          });
          return true;
        } else {
          // Handle errors more gracefully
          this.$swal.fire({
            title: 'Error',
            text:
              response.data.message ||
              'Ocurrió un error al guardar los parámetros.',
            icon: 'error',
          });
          console.error('Error saving parameters:', response.data.message);
          return false;
        }
      } catch (error) {
        // Handle network errors or other exceptions
        this.$swal.fire({
          title: 'Error',
          text: 'No se pudieron actualizar los parámetros del Cliente. Por favor, inténtelo de nuevo más tarde.',
          icon: 'error',
        });
        console.error('Error saving parameters:', error);
      }
    },
    async liquidarFinanciamiento() {
      const query = {
        method: 'post',
        //url: `${API_URL}api/solicitud/liquidacionSubtipo`,
        url: `${API_URL}api/v1/liquidaciones`,
        data: {
          // lineaCreditoCliente_id: this.legajo_id,
          lineacreditocliente_id: this.legajo_id,
          concepto: 'Comprobante genérico para Financiamiento',
          neto: this.monto,
          servicio: this.tipo_servicio,
        },
        headers: {
          token: localStorage.getItem('token'),
        },
      };

      const ok =
        this.params.length > 0 ? await this.guardarCreditoParametros() : true;
      if (ok) {
        const {
          data: { status },
        } = await axios(query);
        if (status === 'success') {
          await this.$swal.fire({
            icon: 'success',
            title: 'Liquidacion Generada',
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit('creditoLiquidado');
          this.$router.go(0);
        } else {
          await this.$swal.fire({
            icon: 'error',
            title: 'Algo salio mal!!',
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        this.$swal('Parametros', 'Algo salio mal', 'error');
      }
    },
  },
};
</script>
