<template>
  <v-dialog v-model="isOpen" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline"
          >{{ modo === 'editar' ? 'Editar Anticipo' : 'Crear Anticipo' }}
        </span>
      </v-card-title>
      <v-card-text class="d-flex justify-center align-center">
        <span class="font-weight-bold">Saldo Disponible: </span>
        <span :class="{ 'red--text': saldoEditable === 0 }">
          {{ saldoFormatted }}
        </span>
      </v-card-text>

      <v-card-text>
        <v-container>
          <v-row>
            <!-- Campo de Fecha con selector de calendario -->
            <v-col cols="12">
              <v-menu
                ref="menu"
                v-model="menuFecha"
                :close-on-content-click="false"
                :return-value.sync="fecha"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaFormat"
                    label="Fecha"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="fecha" no-title scrollable locale="es">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuFecha = false">
                    Cancelar
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(fecha)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <!-- Campos de Anticipo y Gastos con formato de moneda -->
            <v-col cols="12">
              <v-text-field
                label="Anticipo de Cosecha"
                v-model="anticipoFormatted"
                @input="handleAnticipoInput"
                @blur="formatAnticipo"
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Gastos de Elaboración"
                v-model="gastosFormatted"
                @input="handleGastosInput"
                @blur="formatGastos"
                type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="red_label">
              <v-text-field
                label="Gastos Administrativos"
                v-model="gastosAdminFormatted"
                @input="handleGastosAdminInput"
                @blur="formatGastosAdmin"
                type="text"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cerrarModal">Cancelar</v-btn>
        <v-btn color="blue darken-1" text @click="modificar">
          {{ modo === 'editar' ? 'Guardar' : 'Crear' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import { showSimpleMessage } from '../common/util';

export default {
  props: {
    habilitar: Boolean,
    indice: Number,
    data: Object,
    saldoEditable: Number,

    modo: {
      // Nueva propiedad para determinar el modo
      type: String,
      default: 'editar', // Por defecto, asumimos que estamos editando
    },
  },
  data() {
    return {
      menuFecha: false, // Controla la visibilidad del selector de fecha
      fecha: '',
      montoAnterior: 0,
      formatter: new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS',
      }),
    };
  },

  computed: {
    saldoFormatted() {
      return this.formatCurrency(this.saldoEditable);
    },

    isOpen: {
      get() {
        return this.habilitar; // Usa la propiedad `habilitar` para controlar el estado
      },
      set(value) {
        this.$emit('update:habilitar', value); // Emite un evento para actualizar el estado en el padre
      },
    },
    // Formatear el anticipo como moneda
    anticipoFormatted: {
      get() {
        return this.formatCurrency(this.data.monto);
      },
      set(value) {
        const valueAsString = String(value || '');
        const cleanValue = valueAsString.replace(/[$,.]/g, ''); // Eliminar $ y .
        const numericValue = parseFloat(cleanValue) / 100; // Dividir por 100

        // Validar que el valor sea un número válido
        if (isNaN(numericValue)) {
          this.data.monto = 0; // Establecer como 0 si no es válido
        } else {
          this.data.monto = numericValue; // Guardar el valor numérico
        }
      },
    },
    // Formatear los gastos como moneda
    gastosFormatted: {
      get() {
        return this.formatCurrency(this.data.gastos);
      },
      set(value) {
        const valueAsString = String(value || '');
        const cleanValue = valueAsString.replace(/[$,.]/g, ''); // Eliminar $ y .
        const numericValue = parseFloat(cleanValue) / 100; // Dividir por 100

        // Validar que el valor sea un número válido
        if (isNaN(numericValue)) {
          this.data.gastos = 0; // Establecer como 0 si no es válido
        } else {
          this.data.gastos = numericValue; // Guardar el valor numérico
        }
      },
    },
    gastosAdminFormatted: {
      get() {
        return this.formatCurrency(this.data.gastosAdmin);
      },
      set(value) {
        const valueAsString = String(value || '');
        const cleanValue = valueAsString.replace(/[$,.]/g, ''); // Eliminar $ y .
        const numericValue = parseFloat(cleanValue) / 100; // Dividir por 100

        // Validar que el valor sea un número válido
        if (isNaN(numericValue)) {
          this.data.gastosAdmin = 0; // Establecer como 0 si no es válido
        } else {
          this.data.gastosAdmin = numericValue; // Guardar el valor numérico
        }
      },
    },
    // Formatear la fecha
    fechaFormat() {
      if (this.fecha === '') {
        return moment(this.data.fecha, 'DD-MM-YYYY').format('DD-MM-YYYY');
      }
      return moment(this.fecha, 'YYYY-MM-DD').format('DD-MM-YYYY');
    },
  },

  methods: {
    cerrarModal() {
      this.$emit('update:habilitar', false);
    },

    async modificar() {
      if (
        (this.data.monto === 0 && this.data.gastos === 0) ||
        this.data.fecha === '' ||
        isNaN(this.data.monto)
      ) {
        this.$swal.fire(
          'Error',
          `<div>
           Debe completar "Anticipo de Cocecha" u "Gastos de Elaboración" ,
          </div>`
        );

        return;
      }
      const numericAnticipo = parseFloat(this.data.monto) || 0;
      const numericGastos = parseFloat(this.data.gastos) || 0;
      const total = numericAnticipo + numericGastos;

      if (this.saldoEditable === 0 && total >= this.montoAnterior) {
        this.$swal.fire(
          'Error',
          `<div>
            El saldo es:${this.formatter.format(
              parseFloat(this.saldoEditable)
            )}.
          </div>`
        );
        return;
      }

      const monto =
        this.modo === 'editar' ? total + parseFloat(this.saldoEditable) : total;

      // Validar que el anticipo no supere el saldo disponible
      if (
        this.modo !== 'editar' &&
        total >
          parseFloat(Number.isNaN(this.saldoEditable) ? 0 : this.saldoEditable)
      ) {
        this.$swal.fire(
          'Error',
          `<div>
            El monto ingresado no puede superar el Monto máximo ${this.formatter.format(
              parseFloat(this.saldoEditable)
            )}.
          </div>`
        );
        return;
      } else if (
        this.modo === 'editar' &&
        total > parseFloat(this.data.saldoEditable)
      ) {
        this.$swal.fire(
          'Error',
          `<div>
            El monto ingresado no puede superar el Monto máximo ${this.formatter.format(
              parseFloat(this.data.saldoEditable)
            )}.
          </div>`
        );
        return;
      }

      const data = {
        id: this.data.id ? this.data.id : null,
        monto: numericAnticipo,
        gastos: numericGastos,
        gastosAdmin: parseFloat(this.data.gastosAdmin) || 0,
        fecha: this.fechaFormat,
        indice: this.indice,
      };

      if (this.modo === 'editar') {
        this.$emit('editAnticipo', {
          indice: this.indice,

          ...data,
        });
        this.closeModal();
      } else {
        // Emitir los datos actualizados al componente padre
        this.$emit('nuevoAnticipo', {
          indice: this.indice,

          ...data,
        });
        this.closeModal();
      }
    },
    closeModal() {
      this.isOpen = false; // Cierra el modal emitiendo el evento
    },

    // Formato de moneda
    formatCurrency(value) {
      if (value === null || value === undefined || value === '') return '';
      return this.formatter.format(parseFloat(value));
    },

    // Manejar la entrada de Anticipo
    handleAnticipoInput(value) {
      this.anticipoFormatted = value; // Llamar al setter de anticipoFormatted
    },

    // Manejar la entrada de Gastos
    handleGastosInput(value) {
      this.gastosFormatted = value; // Llamar al setter de gastosFormatted
    },
    handleGastosAdminInput(value) {
      this.gastosAdminFormatted = value; // Llamar al setter de gastosFormatted
    },
    // Formatear Anticipo al perder el foco
    formatAnticipo() {
      const numericValue = parseFloat(this.data.monto);
      if (!isNaN(numericValue)) {
        this.data.monto = numericValue;
      }
    },

    // Formatear Gastos al perder el foco
    formatGastos() {
      const numericValue = parseFloat(this.data.gastos);
      if (!isNaN(numericValue)) {
        this.data.gastos = this.data.gastos;
      }
    },
    formatGastosAdmin() {
      const numericValue = parseFloat(this.data.gastosAdmin);
      if (!isNaN(numericValue)) {
        this.data.gastosAdmin = this.data.gastosAdmin;
      }
    },
    calcularMontoAnterior() {
      if (this.montoAnterior > 0) return;
      this.montoAnterior =
        (parseFloat(this.data.monto) || 0) +
        (parseFloat(this.data.gastos) || 0);
    },
  },
  watch: {
    // Observar cambios en data.monto y data.gastos
    'data.monto': {
      handler() {
        this.calcularMontoAnterior();
      },
      immediate: true, // Ejecutar inmediatamente al crear el componente
    },
    'data.gastos': {
      handler() {
        this.calcularMontoAnterior();
      },
      immediate: true, // Ejecutar inmediatamente al crear el componente
    },
  },
  created() {},
};
</script>

<style>
.red_label label {
  color: red !important;
}
</style>
